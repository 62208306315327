<!--<template>
  <div
    class="flex flex-col items-center"
    :style="[layoutStyle, textStyle]"
    :class="[layoutClass, textClass, vAlign]"
  >
    <div v-if="web" class="flex flex-row items-center w-full" :class="align">
      <font-awesome-icon
        :icon="['fas', 'globe']"
        class="mr-4"
      ></font-awesome-icon>
      <span>{{ web }}</span>
    </div>
    <div
      v-if="facebook"
      class="flex flex-row items-center w-full"
      :class="align"
    >
      <font-awesome-icon
        :icon="['fab', 'facebook']"
        class="mr-4"
      ></font-awesome-icon>
      <span>{{ facebook }}</span>
    </div>
    <div
      v-if="instagram"
      class="flex flex-row items-center w-full"
      :class="align"
    >
      <font-awesome-icon
        :icon="['fab', 'instagram']"
        class="mr-4"
      ></font-awesome-icon>
      <span>{{ instagram }}</span>
    </div>
  </div>
</template>-->

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "MyBusinessInfo",
  mixins: [layoutMixin],
  props: {
    web: String,
    facebook: String,
    instagram: String,
  },
  data() {
    return {
      fontSize: "15px",
    };
  },
  render: function(createElement) {
    var renderedIcon = document.getElementById("icon-rendered");
    var iconHtml = renderedIcon.innerHTML;
    var innerHTML =
      '<div class="flex flex-row items-center w-full justify-start">';
    innerHTML += iconHtml + `<span class="ml-4">${this.web}</span></div>`;
    innerHTML +=
      '<div class="flex flex-row items-center w-full justify-start">';
    innerHTML += iconHtml + `<span class="ml-4">${this.facebook}</span></div>`;
    innerHTML +=
      '<div class="flex flex-row items-center w-full justify-start">';
    innerHTML += iconHtml + `<span class="ml-4">${this.instagram}</span></div>`;

    this.adaptFont(innerHTML);

    // text class
    var align = "";
    var vAlign = "";
    var txtClass = "flex flex-col items-center";
    if (this.layout) {
      switch (this.layout.vAlign) {
        case 0:
          vAlign = "justify-start";
          break;
        case 1:
          vAlign = "justify-center";
          break;
        case 2:
          vAlign = "justify-end";
          break;
      }

      switch (this.layout.align) {
        case 0:
          align = "justify-start text-left";
          break;
        case 1:
          align = "justify-center text-center";
          break;
        case 2:
          align = "justify-end text-right";
          break;
      }
    }

    var children = [];

    if (this.web) {
      children.push(
        createElement(
          "div",
          {
            class: ["flex flex-row items-center w-full", align],
          },
          [
            createElement("font-awesome-icon", {
              class: "mr-4",
              props: {
                icon: ["fas", "globe"],
              },
            }),
            this.web,
          ]
        )
      );
    }

    if (this.facebook) {
      children.push(
        createElement(
          "div",
          {
            class: ["flex flex-row items-center w-full", align],
          },
          [
            createElement("font-awesome-icon", {
              class: "mr-4",
              props: {
                icon: ["fab", "facebook"],
              },
            }),
            this.facebook,
          ]
        )
      );
    }

    if (this.instagram) {
      children.push(
        createElement(
          "div",
          {
            class: ["flex flex-row items-center w-full", align],
          },
          [
            createElement("font-awesome-icon", {
              class: "mr-4",
              props: {
                icon: ["fab", "instagram"],
              },
            }),
            this.instagram,
          ]
        )
      );
    }

    return createElement(
      "div",
      {
        class: [
          this.layoutClass,
          this.bgClass,
          txtClass,
          this.customClass,
          vAlign,
        ],
        style: [
          this.layoutStyle,
          this.bgStyle,
          this.textStyle,
          this.customStyle,
        ],
      },
      children
    );
  },
};
</script>

<style></style>
